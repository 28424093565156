/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Cuando me propusieron compartir un blog en común la idea me pareció muy acertada, llevaba tiempo pensando si independizarme de Wordpress.com para tener mi propio hosting pero claro también era un gasto en el que había que pensar y más con mis pocos ingresos. Por eso este co-hosting me ha animado definitivamente, en parte por que sale más barato y en parte por que no empiezo esta andadura solo. Por lo que veo soy el primero que publica algo en el nuevo blog y para mantener el misterio no voy a desvelar la identidad de mis compañeros de dominio pero sí puedo decir que a la mayoría los conozco por el entorno de ", React.createElement(_components.a, {
    href: "http://lasindias.com/"
  }, "Las Indias"), " y también me acompaña algún otro amigo."), "\n", React.createElement(_components.p, null, "No me extenderé mucho, pero después de una intensa conversación buscando nuestros puntos en común decidimos utilizar el nombre de entramado.net por su significado de entramado como una red (cosa que destaca el .net) y no solo una red, si no una red bien sólida. Por otra parte no debemos olvidar el origen de entramado, la trama, una palabra con tantos significados y que nos siguen gustando para definirnos, desde las tramas de una novela, la trama de una tela como otro tipo de red, hasta la trama que es la unidad básica de comunicación en la red, un conjunto ordenado de caracteres o lo que es lo mismo, una retahíla de niveles alto y bajo que permiten la comunicación al más bajo nivel."), "\n", React.createElement(_components.p, null, "No me enrollo más, os dejo con el blog de siempre, como veis no ha cambiado casi nada y en el futuro seguirá así, como siempre ha sido, el sitio donde hablo de mis cosas, que va evolucionando conmigo, sin prisa pero sin pausa."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
